const NotFoundPage = () => {
  return (
    <div className="container mx-auto flex justify-center items-center h-screen flex-col">
      <h1 className="text-6xl font-mono">404</h1>
      <h1 className="text-3xl font-extralight">Not Found</h1>
    </div>
  );
};

export default NotFoundPage;
